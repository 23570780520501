import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import 'lazysizes';
import { replace } from 'connected-react-router';
import { withRouter, Switch, Route } from 'react-router-dom';
import 'styles/style.css';
import AppLoadingContainer from 'containers/AppLoading/AppLoadingContainer';
import PageLoadable from 'components/Loadable/PageLoadable';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import FlashNotification from 'components/FlashNotification/FlashNotification';
import SeatLimitModal from 'components/SeatLimitModal/SeatLimitModal';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { hideFlashNotification } from 'redux/modules/flashNotification';
import { stopScrollRestoration } from 'redux/modules/scrollRestoration';
import { handleLogout } from 'redux/modules/authentication';
import { deleteLoginToken, getLoginToken, getUrlSearchParam, saveLoginToken } from 'utils/helpers';

// const AsyncHome = PageLoadable({ loader: () => import('containers/HomePage/HomeContainer') });
const AsyncNotFoundPage = PageLoadable({ loader: () => import('containers/NotFoundPage/NotFoundPage') });
const AsyncLogin = PageLoadable({ loader: () => import('containers/Login/LoginContainer') });
const AsyncRegistration = PageLoadable({ loader: () => import('containers/Registration/RegistrationContainer') });
const AsyncForgotPassword = PageLoadable({ loader: () => import('containers/ForgotPassword/ForgotPasswordContainer') });
const AsyncWorkspace = PageLoadable({ loader: () => import('containers/Workspace/WorkspaceContainer') });

const SCROLL_DATA_KEY = 'legalworks:scrollPositions';

class AppContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        profileData: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        flashNotificationIsPermanent: PropTypes.bool.isRequired,
        flashNotificationLocation: PropTypes.string.isRequired,
        flashNotificationText: PropTypes.string.isRequired,
        flashNotificationIsWarning: PropTypes.bool.isRequired,
        showFlashNotification: PropTypes.bool.isRequired,
        showSeatLimitModal: PropTypes.bool.isRequired,
        scrollRestorationPath: PropTypes.string,
        history: PropTypes.object.isRequired
    }

    async componentDidMount() {
        const { location } = this.props;
        const token = getUrlSearchParam(location.search, 'auth-token');
        const storedToken = getLoginToken();

        if (token && token !== storedToken) {
            deleteLoginToken();
            saveLoginToken(token);

            return document.location.reload();
        }

        // preload page for 404 route
        AsyncNotFoundPage.preload();

        this.subscribeToIdleTimer();
        this.resetScrollData();
    }

    getSnapshotBeforeUpdate(prevProps) {
        const { history: { action }, location: { pathname } } = prevProps;

        if (action !== 'POP') {
            const data = JSON.parse(localStorage.getItem(SCROLL_DATA_KEY));
            // localStorage.setItem(SCROLL_DATA_KEY, JSON.stringify({ ...data, [pathname]: window.pageYOffset }));
            const workspace = document.getElementsByClassName('workspace');
            let scrollPosition = 0;

            if (workspace.length) {
                scrollPosition = document.getElementsByClassName('workspace')[0].scrollTop;
            }

            localStorage.setItem(SCROLL_DATA_KEY, JSON.stringify({ ...data, [pathname]: scrollPosition }));
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        // const { location: { pathname: prevPath } } = prevProps;
        // const { location: { pathname: path } } = this.props;
        const { history: { action }, scrollRestorationPath, dispatch } = this.props;

        if (action === 'POP') {
            const pos = JSON.parse(localStorage.getItem(SCROLL_DATA_KEY));

            if (pos[scrollRestorationPath]) {
                // setTimeout(() => window.scrollTo({ left: 0, top: pos[scrollRestorationPath], behavior: 'smooth' }));
                const workspace = document.getElementsByClassName('workspace');

                if (workspace.length) {
                    setTimeout(() => { document.getElementsByClassName('workspace')[0].scrollTop = pos[scrollRestorationPath]; });
                    dispatch(stopScrollRestoration());
                }
            }
        }

        if (!this.props.isAuthed && prevProps.isAuthed) {
            this.props.dispatch(replace('/'));

            this.unSubscribeToIdleTimer();
        }

        if ((this.props.isAuthed && !prevProps.isAuthed) ||
            (this.props.profileData.logout_after !== prevProps.profileData.logout_after)) {
            this.subscribeToIdleTimer();
        }
    }

    componentWillUnmount() {
        this.idleTimer && clearTimeout(this.idleTimer);

        this.unSubscribeToIdleTimer();
    }

    subscribeToIdleTimer = () => {
        if (this.props.profileData.logout_after && this.props.isAuthed) {
            this.resetIdleTimer();

            document.addEventListener('mousemove', this.resetIdleTimer);
            document.addEventListener('mousedown', this.resetIdleTimer);
            document.addEventListener('touchstart', this.resetIdleTimer);
            document.addEventListener('keypress', this.resetIdleTimer);
        }
    }

    resetIdleTimer = () => {
        this.idleTimer && clearTimeout(this.idleTimer);

        this.idleTimer = setTimeout(() => {
            this.props.dispatch(handleLogout());
        }, this.props.profileData.logout_after);
    }

    resetScrollData = () => {
        localStorage.setItem(SCROLL_DATA_KEY, JSON.stringify({}));
    }

    unSubscribeToIdleTimer = () => {
        document.removeEventListener('mousemove', this.resetIdleTimer);
        document.removeEventListener('mousedown', this.resetIdleTimer);
        document.removeEventListener('touchstart', this.resetIdleTimer);
        document.removeEventListener('keypress', this.resetIdleTimer);
    }

    handleHideNotification = () => {
        this.props.dispatch(hideFlashNotification());
    }

    render() {
        const { location, isAuthed } = this.props;

        return (
            <div id='outer-container'>
                <ErrorBoundary location={location}>
                    <AppLoadingContainer location={location}>
                        <Switch>
                            <Route path='/' exact={true} component={AsyncLogin} />
                            <Route path='/login' component={AsyncLogin} />
                            <Route path='/registration' component={AsyncRegistration} />
                            <Route path='/forgot-password' component={AsyncForgotPassword} />
                            <PrivateRoute path='/app' component={AsyncWorkspace} isAuthed={isAuthed} />
                            <Route component={AsyncNotFoundPage} />
                        </Switch>
                    </AppLoadingContainer>
                </ErrorBoundary>

                <FlashNotification
                    showFlashNotification={this.props.showFlashNotification}
                    text={this.props.flashNotificationText}
                    location={this.props.flashNotificationLocation}
                    permanent={this.props.flashNotificationIsPermanent}
                    isWarning={this.props.flashNotificationIsWarning}
                    onHideNotification={this.handleHideNotification} />

                <SeatLimitModal
                    dispatch={this.props.dispatch}
                    isOpen={this.props.showSeatLimitModal}
                    profileData={this.props.profileData} />
            </div>
        );
    }
}

function mapStateToProps({ authentication, flashNotification, seatLimit, scrollRestoration }) {
    return {
        isAuthed: authentication.isAuthed,
        profileData: authentication.profileData,
        flashNotificationText: flashNotification.text,
        flashNotificationLocation: flashNotification.location,
        flashNotificationIsPermanent: flashNotification.permanent,
        flashNotificationIsWarning: flashNotification.isWarning,
        showFlashNotification: flashNotification.showFlashNotification,
        showSeatLimitModal: seatLimit.showSeatLimitModal,
        scrollRestorationPath: scrollRestoration.path
    };
}

export default withRouter(
    connect(
        mapStateToProps
    )(AppContainer)
);
