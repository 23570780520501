const functions = {
    availableMethods() {
        return [
            'getAll',
            'markAsRead'
        ];
    },

    markAsRead(data) {
        const url = `${this.modelName}/read`;

        return this._callApi(url, 'post', data);
    }
};

export default functions;
