const functions = {
    availableMethods() {
        return [
            'getAll',
            'getOne',
            'createOne',
            'deleteOne',
            'updateOne',
            'getCaseFileStats',
            'getHashtags'
        ];
    },

    getOne(id, data = {}) {
        const url = `${this.modelName}/${id}`;

        return this._callApi(url, 'get', data);
    },

    getCaseFileStats(data = {}) {
        const url = `${this.modelName}/stats`;

        return this._callApi(url, 'get', data);
    },

    getHashtags(id, template_id) {
        const url = `${this.modelName}/${id}/tags`;

        const data = {
            template_id
        };

        return this._callApi(url, 'get', data);
    }
};

export default functions;
