const functions = {
    availableMethods() {
        return [
            'me',
            'getAll',
            'updateOne',
            'autoFillUser',
            'getClients',
            'createOne',
            'getOne'
        ];
    },

    me() {
        const url = `${this.modelName}/me`;

        return this._callApi(url);
    },

    getClients() {
        const url = `${this.modelName}/clients`;

        return this._callApi(url);
    },

    autoFillUserByName(data = {}) {
        const url = `${this.modelName}/autofill`;

        return this._callApi(url, 'get', data);
    },

    getOne(id, data) {
        const url = `${this.modelName}/${id}`;

        return this._callApi(url, 'get', data);
    },

    getCkToken(data) {
        const url = `${this.modelName}/ck-token`;

        return this._callApi(url, 'get', data);
    },

    checkUserType(email) {
        const url = `${this.modelName}/check-type`;
        const data = {
            email
        };

        return this._callApi(url, 'get', data);
    }

};

export default functions;
