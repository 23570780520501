import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import Notifications, { notify } from 'react-notify-toast';
import './notification.css';

export default class FlashNotification extends PureComponent {
    static propTypes = {
        showFlashNotification: PropTypes.bool.isRequired,
        length: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        permanent: PropTypes.bool.isRequired,
        isWarning: PropTypes.bool.isRequired,
        onHideNotification: PropTypes.func.isRequired
    }

    static defaultProps = {
        length: 5000,
        location: 'top',
        permanent: false,
        isWarning: false
    }

    constructor(props) {
        super(props);

        this.show = notify.createShowQueue();
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        findDOMNode(this.notificationWrapper).addEventListener('transitionend', this.handleTransitionEnd);
    }

    componentDidUpdate(prevProps) {
        const { text, isWarning, length, permanent, showFlashNotification } = this.props;

        if (!showFlashNotification) {
            return;
        }

        this.show(text, isWarning ? 'error' : 'success', permanent ? -1 : length);
    }

    componentWillUnmount() {
        // eslint-disable-next-line react/no-find-dom-node
        findDOMNode(this.notificationWrapper).removeEventListener('transitionend', this.handleTransitionEnd);
    }

    handleTransitionEnd = () => {
        this.props.onHideNotification();
    }

    render() {
        return (
            <Notifications ref={(r) => { this.notificationWrapper = r; }} />
        );
    }
}
