const UPDATE = 'debugData/update';
const UPDATE_TEMPLATE_DATA = 'debugData/updateTemplateData';
const UPDATE_CASE_FILE_DATA = 'debugData/updateCaseFileData';
const UPDATE_HASHTAGS_DATA = 'debugData/updateHashtagsData';
const UPDATE_LETTERHEAD_HASHTAGS_DATA = 'debugData/updateLetterheadHashtagsData';
const UPDATE_LETTERHEAD_DATA = 'debugData/updateLetterheadData';

export function update(payload) {
    return {
        type: UPDATE,
        payload
    };
}

export function updateTemplateData(payload) {
    return {
        type: UPDATE_TEMPLATE_DATA,
        payload
    };
}

export function updateCaseFileData(payload) {
    return {
        type: UPDATE_CASE_FILE_DATA,
        payload
    };
}

export function updateHashtagsData(payload) {
    return {
        type: UPDATE_HASHTAGS_DATA,
        payload
    };
}

export function updateLetterheadHashtagsData(payload) {
    return {
        type: UPDATE_LETTERHEAD_HASHTAGS_DATA,
        payload
    };
}

export function updateLetterheadData(payload) {
    return {
        type: UPDATE_LETTERHEAD_DATA,
        payload
    };
}

const initialState = {
    hashtags: 'Please open the letter page',
    orphaned_hashtags: 'Please open the letter page'
};

export default function debugData(state = initialState, action) {
    switch (action.type) {
        case UPDATE:
            return {
                ...action.payload
            };
        case UPDATE_TEMPLATE_DATA:
            return {
                ...state,
                hashtags: state.hashtags,
                // letterhead_hashtags: state.letterhead_hashtags,
                template: action.payload
                    ? {
                        id: action.payload.id,
                        checklist_item: {
                            id: action.payload.checklist_item.id,
                            description: action.payload.checklist_item.description
                        },
                        master_template: {
                            id: action.payload.template.id,
                            name: action.payload.template.name,
                            // is_with_letterhead: action.payload.template.is_with_letterhead,
                            hashtags: action.payload.template.hashtags,
                            content: action.payload.template.content
                        },
                        file: action.payload.file?.file_link,
                        content: action.payload.content,
                    }
                    : null,
                case_file: state.case_file,
                // templateRaw: { ...action.payload }
            };
        case UPDATE_CASE_FILE_DATA:
            return {
                ...state,
                hashtags: state.hashtags,
                // letterhead_hashtags: state.letterhead_hashtags,
                orphaned_hashtags: action.payload.transaction_type.orphaned_hashtags,
                template: state.template,
                case_file: {
                    id: action.payload.id,
                    subject_matter: action.payload.subject_matter,
                    address: action.payload.address,
                    completion_date: action.payload.completion_date,
                    is_external: action.payload.meta?.is_external,
                    data: action.payload.meta?.data || {},
                    transation_type_data: JSON.parse(action.payload.transaction_type_data),
                    transaction_type: {
                        id: action.payload.transaction_type.id,
                        name: action.payload.transaction_type.name,
                        fields: action.payload.transaction_type.fields
                    },
                },
                // caseFileRaw: { ...action.payload }
            };
        case UPDATE_HASHTAGS_DATA:
            return {
                ...state,
                hashtags: action.payload
                    ? action.payload
                    : null,
                // letterhead_hashtags: state.letterhead_hashtags,
                template: state.template,
                case_file: state.case_file
            };
        case UPDATE_LETTERHEAD_HASHTAGS_DATA:
            return {
                ...state,
                hashtags: state.hashtags,
                // letterhead_hashtags: action.payload,
                template: state.template,
                case_file: state.case_file
            };
        case UPDATE_LETTERHEAD_DATA:
            return {
                ...state,
                // letterhead: action.payload
            };
        default:
            return state;
    }
}
