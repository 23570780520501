const Eloquent = window.Eloquent;

/**
 * Get law firm
 * @param {Number} id
 * @returns {Promise}
 */
export function getLawFirm(id) {
    return Eloquent.LawFirm.getOne(id);
}

/**
 * Update law firm
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateLawFirm(id, data) {
    return Eloquent.LawFirm.updateOne(id, data);
}
