import _ from 'lodash';
import axios from 'axios';
import Eloquent from './Eloquent';

const baseDomain = process.env.REACT_APP_API_URL;

const settings = {
    baseDomain: (process.env.NODE_ENV !== 'production' && window._playground) ? '' : baseDomain
};

// console.log([
//     'ELOQUENT: ',
//     process.env,
//     settings
// ]);

const _axios = axios.create({
    baseURL: settings.baseDomain,
    withCredentials: true,
    validateStatus: status => status >= 200 && status < 500,
});

_axios.interceptors.response.use(response => {
    response.isOk = response.status >= 200 && response.status < 300;

    return response;
});

const models = {
    Auth: require('./Auth').default,
    User: require('./User').default,
    CaseFile: require('./CaseFile').default,
    LawFirm: require('./LawFirm').default,
    Folder: require('./Folder').default,
    File: require('./File').default,
    TransactionType: require('./TransactionType').default,
    ChecklistItem: require('./ChecklistItem').default,
    ChecklistitemTemplate: require('./ChecklistitemTemplate').default,
    Task: require('./Task').default,
    Comment: require('./Comment').default,
    Notification: require('./Notification').default,
    Template: require('./Template').default
};

_.each(models, (modelFunctions, modelName) => {
    Eloquent[modelName] = Eloquent._makeClass(modelName, modelFunctions);
});

window.Eloquent = Eloquent;
window._axios = _axios;

export { _axios, Eloquent };
