const functions = {
    availableMethods() {
        return [
            'getOne',
            'updateOne'
        ];
    }
};

export default functions;
