import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Modal from 'components/Modal/Modal';
import Spinner from 'components/Spinner/Spinner';
import { hideSeatLimitModal } from 'redux/modules/seatLimit';
import { showFlashNotification } from 'redux/modules/flashNotification';
import { updateProfileData } from 'redux/modules/authentication';
import { updateLawFirm } from 'api/lawFirms';
import './seat-limit-modal.css';

const initialState = {
    newSeatLimit: '',
    isInvalidSeatLimit: false
};

export default class SeatLimitModal extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        profileData: PropTypes.object.isRequired
    }

    state = {
        ...initialState,
        isUpdating: false
    }

    handleToggleModal = () => {
        this.props.dispatch(hideSeatLimitModal());
        this.setState({ ...initialState });
    }

    handleSeatLimitChange = (e) => {
        const { law_firm: lawFirm } = this.props.profileData;
        const seatLimit = +e.target.value || '';

        if (seatLimit <= lawFirm.seat_limit) {
            this.setState({ isInvalidSeatLimit: true, newSeatLimit: seatLimit });
        } else {
            this.setState({ isInvalidSeatLimit: false, newSeatLimit: seatLimit });
        }
    }

    handleUpdateSeatLimit = async () => {
        this.setState({
            isUpdating: true
        });

        try {
            await updateLawFirm(this.props.profileData.law_firm_id, {
                seat_limit: this.state.newSeatLimit
            });

            this.props.dispatch(updateProfileData({
                law_firm: {
                    ...this.props.profileData.law_firm,
                    seat_limit: this.state.newSeatLimit
                }
            }));

            this.props.dispatch(showFlashNotification({
                text: 'Law Firm Updated',
                isSuccess: true
            }));
        } catch (err) {
            this.props.dispatch(showFlashNotification({
                text: 'An error occured while submitting data.',
                isWarning: true
            }));
        } finally {
            this.handleToggleModal();
            this.setState({
                isUpdating: false
            });
        }
    }

    render() {
        const { law_firm: lawFirm } = this.props.profileData;

        if (!lawFirm) {
            return null;
        }

        return (
            <Modal
                isOpen={this.props.isOpen}
                onToggle={this.handleToggleModal}
                header='Notification'>
                <div className='global-modal__notif-seats-text -danger'>You have {lawFirm.available_seats} out of {lawFirm.seat_limit} seats left.</div>
                <div className='global-modal__notif-seats-text -success'>Increase Seat Limit</div>
                <div className='global-modal__notif-seats-inputs'>
                    <FormGroup>
                        <Input type='number' className='global-modal__notif-seats-input' value={this.state.newSeatLimit}
                            onChange={this.handleSeatLimitChange} />
                    </FormGroup>

                    <FormFeedback className={`global-modal__feedback-message ${this.state.isInvalidSeatLimit ? '-show' : ''}`}>
                        Please enter valid seat limit. It should be more than {lawFirm.seat_limit}.
                    </FormFeedback>
                </div>
                <div className='global-modal__notif-seats-buttons'>
                    <Button className='global-modal__notif-seats-ok btn--primary'
                        disabled={this.state.isInvalidSeatLimit}
                        onClick={this.handleUpdateSeatLimit}>
                        {this.state.isUpdating && (
                            <Spinner size={20} color='#fff' className='mr-2'/>
                        )}
                        SUBMIT
                    </Button>
                </div>
            </Modal>
        );
    }
}
