import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import BootstrapModal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import './modal.css';

export default class Modal extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        isOpen: PropTypes.bool.isRequired,
        onToggle: PropTypes.func.isRequired,
        header: PropTypes.string,
        children: PropTypes.node.isRequired,
        size: PropTypes.string
    }

    render() {
        return (
            <BootstrapModal
                className={`main-modal ${this.props.className}`}
                contentClassName='main-modal-content'
                size={this.props.size}
                isOpen={this.props.isOpen}
                toggle={this.props.onToggle}>

                <ModalHeader className='main-modal-header' toggle={this.props.onToggle}>
                    {this.props.header}
                </ModalHeader>

                <ModalBody className='main-modal-body'>
                    {this.props.children}
                </ModalBody>

            </BootstrapModal>
        );
    }
}
