const RESTORE_SCROLL_POSITIONS = 'RESTORE_SCROLL_POSITIONS';
const STOP_SCROLL_RESTORATION = 'STOP_SCROLL_RESTORATION';

export function restoreScrollPositions(path) {
    return {
        type: RESTORE_SCROLL_POSITIONS,
        path
    };
}

export function stopScrollRestoration() {
    return {
        type: STOP_SCROLL_RESTORATION
    };
}

const initialState = {
    path: undefined
};

export default function scrollRestoration(state = initialState, action) {
    switch (action.type) {
        case RESTORE_SCROLL_POSITIONS:
            return {
                path: action.path
            };
        case STOP_SCROLL_RESTORATION:
            return {
                path: undefined
            };
        default:
            return state;
    }
}
