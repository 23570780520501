import _ from 'lodash';
import { _axios } from './app';

class BaseModel {
    constructor(attributes, exists = false) {
        this._exists = exists;
        _.forEach(attributes, (value, key) => {
            // remove this bracket will cause issue if value = false, it will exit loop.
            this[key] = value;
        });
    }

    save() {
        if (this._exists) {
            return this.update();
        }

        return this.insert();
    }

    insert() {
        const url = `${this.modelName}/create`;

        return this._callApi(url, 'post', this._getAttributes());
    }

    update() {
        const url = `${this.modelName}/${this.id}`;

        return this._callApi(url, 'post', this._getAttributes());
    }

    delete() {
        const url = `${this.modelName}/${this.id}/delete`;

        return this._callApi(url, 'post');
    }

    get modelName() {
        return this.constructor.modelName;
    }

    _getAttributes() {
        const attributes = {};

        _.forEach(this, (value, key) => {
            if (!_.startsWith(key, '_')) {
                attributes[key] = value;
            }
        });

        return attributes;
    }

    _callApi(...agrs) {
        return this.constructor._callApi(...agrs);
    }

    static setModelName(name) {
        this.modelName = name;
    }

    static availableMethods() {
        throw new Error('Available Methods are not defined!');
    }

    static getOne(id) {
        const url = `${this.modelName}/${id}`;

        return this._callApi(url);
    }

    static getAll(data) {
        const url = `${this.modelName}`;

        return this._callApi(url, 'get', data);
    }

    static createOne(data) {
        const url = `${this.modelName}/create`;

        return this._callApi(url, 'post', data);
    }

    static createMany(data) {
        const url = `${this.modelName}/create-many`;

        return this._callApi(url, 'post', data);
    }

    static updateOne(id, data) {
        const url = `${this.modelName}/${id}`;

        return this._callApi(url, 'post', data);
    }

    static deleteOne(id) {
        const url = `${this.modelName}/${id}/delete`;

        return this._callApi(url, 'post');
    }

    static getFillableAttributes() {
        const url = `attributes/fillable/${this.modelName}`;

        return this._callApi(url);
    }

    static getAllAttributes() {
        const url = `attributes/all/${this.modelName}`;

        return this._callApi(url);
    }

    static _callApi(url, method = 'get', data = {}) {
        return new Promise((resolve, reject) => {
            _axios.request({
                url: url,
                method,
                data: method !== 'get' ? data : null,
                params: method === 'get' ? data : {}
            })
                .then(data => {
                    if (data.data instanceof Array) {
                        // collection response
                        if (data.data[0] instanceof Object) {
                            const cloned = [];

                            _.forEach(data.data, value => cloned.push(new this(value, true)));

                            data.data = cloned;

                            return resolve(data);
                        }

                        // normal array or nothing found
                        return resolve(data);
                    }


                    if (data.data instanceof Object) {
                        // single model response
                        if (data.data.id !== undefined) {
                            data.data = new this(data.data, true);

                            return resolve(data);
                        }

                        // pagination result
                        if (data.data.data instanceof Array) {
                            const cloned = [];

                            _.forEach(data.data.data, value => cloned.push(new this(value, true)));

                            data.data.data = cloned;

                            return resolve(data);
                        }

                        return resolve(data);
                    }

                    return resolve(data);
                })
                .catch(err => reject(err));
        })
    }
}

export default BaseModel;
