import Eloquent from './Eloquent';

const functions = {
    availableMethods() {
        return [
            'login',
            'logout',
            'register',
            'sendResetPasswordCode',
            'resetPassword',
            'checkCode'
        ];
    },

    login(data = {}) {
        const url = `${this.modelName}/login`;

        return this._callApi(url, 'post', data);
    },

    logout() {
        const url = `${this.modelName}/logout`;

        return this._callApi(url, 'post').then(
            () => {
                Eloquent.unsetApiRequestToken();
            }
        );

    },

    register(data) {
        const url = `${this.modelName}/reg`;

        return this._callApi(url, 'post', data);
    },

    refreshToken(data) {
        const url = `${this.modelName}/refresh`;

        return this._callApi(url, 'post', data);
    },

    checkLoginStatus() {
        const url = `${this.modelName}/check`;

        return this._callApi(url, 'get');
    },

    // checkEmail(email) {
    //     const url = `${this.modelName}/email/check`
    //
    //     const data = {
    //         email
    //     }
    //
    //     return this._callApi(url, 'post', data)
    // },

    sendResetPasswordCode(email) {
        const url = 'password/forgot';

        const data = {
            email
        };

        return this._callApi(url, 'post', data);
    },

    resetPassword(token, password) {
        const url = 'password/reset';

        const data = {
            token,
            password
        };

        return this._callApi(url, 'post', data);
    },

    checkCode(token) {
        const url = 'password/reset/check';

        const data = {
            token
        };

        return this._callApi(url, 'post', data);
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('This model do not have attributes');
        });
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('This model do not have attributes');
        });
    }
};

export default functions;
