const SHOW_SEAT_LIMIT_MODAL = 'SHOW_SEAT_LIMIT_MODAL';
const HIDE_SEAT_LIMIT_MODAL = 'HIDE_SEAT_LIMIT_MODAL';

export function showSeatLimitModal() {
    return {
        type: SHOW_SEAT_LIMIT_MODAL
    };
}

export function hideSeatLimitModal() {
    return {
        type: HIDE_SEAT_LIMIT_MODAL
    };
}

const initialState = {
    showSeatLimitModal: false
};

export default function seatLimit(state = initialState, action) {
    switch (action.type) {
        case SHOW_SEAT_LIMIT_MODAL:
            return {
                showSeatLimitModal: true
            };

        case HIDE_SEAT_LIMIT_MODAL:
            return initialState;

        default:
            return state;
    }
}
