const functions = {
    availableMethods() {
        return [
            'updateOne',
            'createOne',
            'deleteOne'
        ];
    }

};

export default functions;
