import _ from 'lodash';
import { _axios } from './app';
import Model from './Model';

let apiRequestToken;

const Eloquent = {
    Model,

    setApiRequestToken(token) {
        apiRequestToken = _axios.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${token || ''}`;

            return config;
        });
    },

    unsetApiRequestToken() {
        _axios.interceptors.request.eject(apiRequestToken);
    },

    addResponseInterceptor(handler) {
        _axios.interceptors.response.use(response => {
            handler(response);

            response.isOk = response.status >= 200 && response.status < 300;

            return response;
        });
    },

    _makeClass(modelName, modelFunctions) {
        const NewModel = class extends this.Model { };

        NewModel.setModelName(_.kebabCase(modelName));

        _.each(modelFunctions, (fn, functionName) => {
            NewModel[functionName] = fn;
            NewModel.prototype[functionName] = fn;
        });

        return NewModel;
    }
};

export default Eloquent;
