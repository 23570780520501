const functions = {
    availableMethods() {
        return [
            'updateOne',
            'deleteOne',
            'share',
            'makePdf'
        ];
    },

    share(id, data = {}) {
        const url = `${this.modelName}/${id}/share`;

        return this._callApi(url, 'post', data);
    },

    makePdf(data = {}) {
        const url = `${this.modelName}/pdf`;

        return this._callApi(url, 'post', data);
    },

    sendByEmail(id, data = {}) {
        const url = `${this.modelName}/${id}/email`;

        return this._callApi(url, 'post', data);
    },

    getShareLog(id) {
        const url = `${this.modelName}/${id}/shared-log`;

        return this._callApi(url);
    }
}

export default functions;
