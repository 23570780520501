const functions = {
    availableMethods() {
        return [
            'getAll',
            'getOne',
            'deleteOne'
        ];
    }
};

export default functions;
