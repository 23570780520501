const Eloquent = window.Eloquent;

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export function getAuthedUserProfile() {
    return Eloquent.User.me();
}

/**
 * Create a new user
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export async function getUserById(id, data) {
    return Eloquent.User.getOne(id, data);
}

/**
 * Set logged in user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function setUserProfile(id, data) {
    return Eloquent.User.updateOne(id, data);
}

/**
 * Get all clients of a user
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllClients(data) {
    return Eloquent.User.getClients(data);
}

/**
 * Get all clients of a lawfirm
 * @param {Object} data
 * @returns {Promise}
 */
export function getLawfirmClients(data = {}) {
    return Eloquent.User.getAll({ ...data, system_role: 4 });
}

/**
 * Autofill a new user
 * @returns {Promise}
 */
export function autoFillUser() {
    return Eloquent.User.autoFillUser();
}

/**
 * Get all staff
 * @param {Number} lawFirmId
 * @returns {Promise}
 */
export function getAllStaff(lawFirmId) {
    return Eloquent.User.getAll({ law_firm_id: lawFirmId });
}

/**
 * Create user
 * @param {Object} data
 * @returns {Promise}
 */
export function createUser(data) {
    return Eloquent.User.createOne(data);
}

/**
 * Update user
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateUser(id, data) {
    return Eloquent.User.updateOne(id, data);
}

/**
 * Get CKEditor token
 * @param {Object} data
 * @returns {Promise}
 */
export function getCKToken(data) {
    return Eloquent.User.getCkToken(data);
}

/**
 * Check user's user role
 * @param {String} email
 * @returns {Promise}
 */
export function checkUserRole(email) {
    return Eloquent.User.checkUserType(email);
}
