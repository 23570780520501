const functions = {
    availableMethods() {
        return [
            'updateOne',
            'getOne',
            'resetTemplate'
        ];
    },

    resetTemplate(id) {
        const url = `${this.modelName}/${id}/reset`;

        return this._callApi(url, 'post');
    }
};

export default functions;
